import { useEffect, useState } from "react";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getBase64, beforeUpload } from "../../utils";
import { URLS } from "../../utils";
const Index = (props) => {
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        props?.form?.setFieldValue(props?.name, info.file.originFileObj);
        setImageUrl(url);
      });
    }
  };
  useEffect(() => {
    if (props?.value) {
      if (props?.value?.toString()?.includes("Resource"))
        setImageUrl(`${URLS?.main}/${props?.value}`);
      else {
        getBase64(props.value, (url) => {
          setImageUrl(url);
        });
      }
    }
  }, [props?.value]);

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const customUpload = (options) => {
    return options?.onSuccess("ok", { success: true });
  };
  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={customUpload}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          className="w-full h-full object-contain"
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default Index;
