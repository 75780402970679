import { CloseOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useState } from "react";
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const clickItem = () => {
    props?.onClick && props?.onClick(props?.data);
  };

  const handleDeleteNotif = () => {
    setLoading(true);
    props
      ?.request({
        url: `/notification/user/${props?.data?.id}`,
        method: "DELETE",
        ismessage: true,
      })
      ?.then((res) => {
        setLoading(false);
        if (res.success) props?.reload();
      });
  };

  return (
    <div
      className={`flex flex-col px-3 py-3 border relative ${
        props?.data?.isview === 0 ? "border-active" : ""
      } cursor-pointer bg-white dark:bg-black shadow-md hover:shodow-lg rounded-2xl`}
    >
      <div className="flex items-center justify-between" onClick={clickItem}>
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-10 h-10 rounded-2xl p-2 border border-blue-100 text-blue-400 bg-blue-50"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div className="flex flex-col ml-3">
            <div className="font-medium text-lg leading-none">
              {props?.data?.header || props?.data?.carnumber}
            </div>
            <p className="text-sm text-gray-600 leading-none mt-1">
              {props?.data?.note || props?.data?.result}
            </p>
          </div>
        </div>
      </div>
      <Popconfirm
        title={"Мэдэгдэл устгахдаа итгэлтэй байна уу ?"}
        placement="bottom"
        onConfirm={handleDeleteNotif}
        okText="Тийм"
        cancelText="Үгүй"
      >
        <Button
          icon={<CloseOutlined />}
          className="absolute top-2 right-2"
          size="small"
        />
      </Popconfirm>
    </div>
  );
};

export default Index;
