import { Card, Avatar, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { USER_FORM } from "../../data/forms";
import { Form, Cards } from "../../components";
import { ADDRESS, PHONE } from "../../data";
import dayjs from "dayjs";
const Index = (props) => {
  const isBefore = dayjs(props?._auth?.enddate).isBefore(dayjs());
  return (
    <div className="md:w-2/6 sm:w-full">
      <Card hoverable>
        <div className="flex items-center justify-center relative">
          <Avatar shape="square" size={64} icon={<UserOutlined />} />
          <div className="absolute top-0 right-0 flex flex-col">
            <span className="text-xs">Лиценз:</span>
            <Button type="primary">
              {isBefore
                ? "Дууссан"
                : dayjs(props?._auth?.enddate)?.format("YYYY-MM-DD")}
            </Button>
          </div>
        </div>
        <div className="mt-3">
          <Form
            initialValues={{ ...props?._auth?.user }}
            data={USER_FORM}
            hideBtn={true}
            {...props}
          />
          <Cards.ColorCard {...props} />
          <Cards.SetPinCard {...props} />
          <Cards.ChangePassCard {...props} />
          <div className="flex flex-col items-center justify-center mt-4">
            <span className="text-xs">{ADDRESS}</span>
            <span className="text-xs">{PHONE}</span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Index;
