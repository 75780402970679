import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form as AntdForm } from "antd";
import { Form } from "../../../components";
import { URLS } from "../../../utils";

const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = AntdForm.useForm();
  const data = JSON.parse(localStorage.getItem("register"));
  useEffect(() => {
    document.title = "Нууц үг сэргээх";
  }, []);

  const handleSave = (e) => {
    if (data.type === "forgetpass") {
      setLoading(true);
      props
        .request({
          url: `/user/checkresetpass/${data?.phone}/${e.otp}`,
          method: "POST",
          ismessage: true,
        })
        .then((res) => {
          setLoading(false);
          if (res.success) {
            let tmp = {
              ...data,
              otp: e.otp,
            };
            localStorage.setItem("register", JSON.stringify(tmp));
            navigate("/register/password");
          }
        });
    } else {
      setLoading(true);
      props
        .request({
          url: `/user/checkotp/${data?.phone}/${e.otp}`,
          method: "POST",
          ismessage: true,
        })
        .then((res) => {
          setLoading(false);
          if (res.success) {
            let tmp = {
              ...data,
              otp: e.otp,
            };
            localStorage.setItem("register", JSON.stringify(tmp));
            navigate("/register/password");
          }
        });
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <div className="bg-white dark:bg-gray-900 h-full">
      <div className="flex justify-center h-full ">
        <div
          className="hidden bg-cover bg-no-repeat lg:block lg:w-3/6 "
          style={{
            backgroundImage: `url(${
              URLS.main
            }/${props?.resloginbanner?.loginimgnm?.replace(/\\/g, "/")})`,
          }}
        >
          <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40 rounded-lg">
            <div>
              <h2 className="text-4xl font-bold text-white">Automotive</h2>

              <p className="max-w-xl mt-3 text-gray-300">
                Та өөрийн автомашинаа хаанаас ч удирдах боломжтой.
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
          <div className="flex-1">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">
                {data.type === "forgetpass" ? "Нууц үг сэргээх" : "Бүртгүүлэх"}
              </h2>

              <p className="mt-3 text-gray-500 dark:text-gray-300">
                Бүртгэлтэй утасны дугаарт ирсэн кодыг оруулна уу.
              </p>
            </div>

            <div className="mt-8">
              <Form
                form={form}
                data={[
                  {
                    type: "otp",
                    placeholder: "Баталгаажуулах код",
                    name: "otp",
                    required: true,
                    size: "large",
                  },
                ]}
                handleSave={handleSave}
                loading={loading}
                className="mt-2"
                size="large"
                btnTitle="main.next"
                {...props}
              />

              <div className="flex mt-4 justify-center">
                <div
                  onClick={handleBack}
                  className="cursor-pointer text-sm text-center text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline"
                >
                  Буцах
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
