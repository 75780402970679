import { Search, Table, Modal, Form } from "../../components";
import { useState, useRef, useEffect } from "react";
import { Form as AntForm, message } from "antd";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
const Index = (props) => {
  const editRef = useRef(null);
  const [mainForm] = AntForm.useForm();
  const [searchForm] = AntForm.useForm();
  const [data, setData] = useState([]);
  const [listOpen, setListOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAllUser, setIsAllUser] = useState(true);
  const [customData, setCustomData] = useState({});
  const location = useLocation();
  useEffect(() => {
    editRef.current = null;
  }, [location?.pathname]);

  const search = (e) => {
    if (props?.config?.doubleClick?.url) {
      props
        .request({
          url: `${props?.config?.doubleClick?.url}/${e.id}`,
          method: "POST",
        })
        .then((res) => {
          if (res.success) {
            setData(res.data);
            setListOpen(true);
          }
        });
    }
  };

  const onClose = () => setOpen(false);

  const handleSave = (values) => {
    if (props?.config?.addform?.url === "/admin/useredit")
      values.userid = values?.id;
    setLoading(true);
    props
      .request({
        url: !isAllUser
          ? props?.config?.addform?.securl
          : props?.config?.addform?.url,
        method: !isAllUser
          ? props?.config?.addform?.secmethod
          : props?.config?.addform?.method,
        isfile: props?.config?.addform?.isfile,
        body: values,
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          props?.config?.addform?.nextUrl?.map((item) => {
            props?.request({
              url: item.url,
              method: item.method,
              model: item.model,
            });
            return null;
          });
          if (!isAllUser) setIsAllUser(true);
          mainForm.resetFields();
          searchForm.submit();
          onClose();
        }
      });
  };

  const handleNew = () => {
    mainForm?.resetFields();
    if (customData) setCustomData({});
    setOpen(true);
  };

  const handleEdit = () => {
    if (editRef?.current) {
      let editData = { ...editRef?.current };
      if (editData?.sdate) editData.sdate = dayjs(editData?.sdate);
      if (editData?.edate) editData.edate = dayjs(editData?.edate);
      if (editData?.imgnm) editData.img = editData?.imgnm;
      if (editData?.loginimgnm) editData.loginimg = editData?.loginimgnm;
      if (editData?.users) {
        setCustomData({ ...customData, customusers: editData?.users || [] });
      }
      mainForm?.setFieldsValue({ ...editData });
      setOpen(true);
    } else message.error("Мөр сонгож засах үйлдэл хийнэ үү.");
  };
  return (
    <div className="w-full h-full">
      <Search
        {...props}
        form={searchForm}
        handleNew={handleNew}
        handleEdit={handleEdit}
      />
      <Table {...props} search={search} editRef={editRef} open={open} />
      {props?.config?.addform && (
        <Modal
          title={props?.translate(props?.config?.addform?.title)}
          open={open}
          onClose={onClose}
          width={800}
          isShowFooter={false}
        >
          <Form
            data={props?.config?.addform?.data}
            handleSave={handleSave}
            form={mainForm}
            loading={loading}
            className="mt-2"
            btnTitle="main.save"
            isAllUser={isAllUser}
            setIsAllUser={setIsAllUser}
            customData={customData}
            {...props}
          />
        </Modal>
      )}
      {props?.config?.doubleClick && (
        <Modal
          title={props?.config?.doubleClick?.title}
          open={listOpen}
          width={650}
          onClose={() => setListOpen(false)}
          isShowFooter={false}
        >
          <Table
            bordered
            size="small"
            dataSource={data || []}
            columns={props?.config?.doubleClick?.columns || []}
          />
        </Modal>
      )}
    </div>
  );
};

export default Index;
