import { useState } from "react";
import { Input, Form, Button } from "antd";
import { Controls } from "..";
import iFace from "../../assets/icons/iFace.png";
import iFinger from "../../assets/icons/iFinger.png";
import { Modal, QRCodeScanner } from "..";
import dayjs from "dayjs";

const Index = (props) => {
  const [qrOpen, setQrOpen] = useState(false);

  const getOptions = (options) => {
    if (options) {
      if (Array.isArray(options)) {
        options?.map((item, i) => {
          item.label = props?.translate(item?.label);
        });
        return options;
      } else {
        if (options?.includes("custom")) {
          if (props?.customData[options]) return props?.customData[options];
        } else if (props[`res${options}`]) return props[`res${options}`];
      }
    }
    return [];
  };
  const getFormItem = (item, key) => {
    if (item?.isShowOrFalse) item.options = props?.resuserspopup;
    return (
      <Form.Item
        key={key}
        name={item?.name}
        valuePropName={item?.valuePropName}
        hidden={
          item?.hidden
            ? item?.hidden
            : props?.isAllUser && item?.isShowOrFalse
            ? true
            : false
        }
        rules={
          item?.rules
            ? item.rules
            : [
                {
                  required: item?.required,
                  message:
                    props?.lang === "mn"
                      ? `${props?.translate(item?.placeholder)} оруулна уу`
                      : `Choose ${props?.translate(item?.placeholder)}`,
                },
              ]
        }
      >
        <Controls
          placeholder={props?.translate(item?.placeholder)}
          type={item?.type}
          form={props?.form}
          name={item?.name}
          size={item?.size}
          mode={item?.mode}
          setQrOpen={setQrOpen}
          disabled={item?.disabled}
          fieldNames={item.fieldNames}
          options={getOptions(item?.options)}
          optionFilterProp={item?.optionFilterProp}
          onChange={(e) =>
            item?.type === "radio" && props?.setIsAllUser(e?.target?.value)
          }
        />
      </Form.Item>
    );
  };

  const handleScanSuccess = (text) => {
    props?.form?.setFieldsValue({ imei: text });
    setTimeout(() => {
      setQrOpen(false);
    }, 500);
  };

  const getBioBtn = () => {
    if (props?.mobiledata?.bioType && props?.fingerClicked) {
      const { bioType } = props?.mobiledata;
      if (
        bioType === "FINGER" ||
        bioType === "FACEID" ||
        bioType === "TOUCHID"
      ) {
        return (
          <Button
            onClick={props?.fingerClicked ? props?.fingerClicked : undefined}
            size="large"
            className="w-fit flex items-center justify-center ml-2"
          >
            <img
              className="w-12 active-icon"
              src={bioType === "FACEID" ? iFace : iFinger}
            />
          </Button>
        );
      }
    }
    return null;
  };

  const handleScanFailure = (error) => {};
  return (
    <Form
      name={props?.name || "form"}
      form={props?.form}
      layout={props?.layout ? props?.layout : "vertical"}
      initialValues={{
        id: 0,
        edate: dayjs(),
        sdate: props?.lastMonth ? dayjs().subtract(1, "month") : dayjs(),
        isenable: 1,
        ...props?.initialValues,
      }}
      onFinish={props?.handleSave ? props?.handleSave : undefined}
      className={props?.className ? props?.className : ""}
      /*  className={`${
        props?.className ? props?.className : "flex flex-col gap-2"
      }`} */
    >
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <div
        className={`${
          props?.name === "search" &&
          "grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-4 md:grid-cols-10 lg:grid-cols-10 xl:grid-cols-10 search-form gap-1"
        }`}
      >
        {props?.data?.map((item, i) => {
          if (item?.list && item?.list?.length) {
            return (
              <div className="flex gap-2 grid grid-cols-3">
                {item?.list?.map((item1, i1) => getFormItem(item1, i1))}
              </div>
            );
          }
          return (
            <div>
              {props?.name === "search" ? null : (
                <span className="text-xs">
                  {props?.translate(item?.placeholder)}
                </span>
              )}

              {getFormItem(item, i)}
            </div>
          );
        })}
        {props?.data?.length % 2 > 0 ? (
          <div className="visible sm:visible md:hidden lg:hidden" />
        ) : null}
        <div className={`flex gap-1`}>
          <Button
            type="primary"
            htmlType="submit"
            size={props?.size}
            className={props?.name !== "search" && "w-full"}
            loading={props?.loading === true}
          >
            {props?.translate(props?.btnTitle ? props?.btnTitle : "main.save")}
          </Button>
          {getBioBtn()}
          {props?.isAddForm && (
            <Button
              onClick={props?.handleNew}
              loading={props?.loading === true}
            >
              {props?.translate("main.new")}
            </Button>
          )}
          {props?.isAddForm && (
            <Button
              onClick={props?.handleEdit}
              loading={props?.loading === true}
            >
              {props?.translate("main.edit")}
            </Button>
          )}
        </div>
      </div>

      <Modal
        title={props?.translate("main.scanqr")}
        open={qrOpen}
        onClose={() => setQrOpen(false)}
        width={750}
        isShowFooter={false}
        height={"80%"}
      >
        <div className="p-5">
          <QRCodeScanner
            open={qrOpen}
            onScanSuccess={handleScanSuccess}
            onScanFailure={handleScanFailure}
          />
        </div>
      </Modal>
    </Form>
  );
};

export default Index;
