import Btn from "./Btn";
import iSun from "../../assets/icons/iSun.png";
import iMoon from "../../assets/icons/iMoon.png";
const Index = (props) => {
  const changeTheme = (value) => {
    localStorage.setItem("theme", value);
    props?.setTheme(value);
  };

  return (
    <div
      className={`bg-black p-2 flex rounded-full gap-4 ${
        !props?.show && "hidden md:flex"
      }`}
    >
      <Btn
        active={props?.theme === "dark"}
        value="dark"
        icon={iMoon}
        onClick={changeTheme}
      />
      <Btn
        active={props?.theme === "light"}
        value="light"
        icon={iSun}
        onClick={changeTheme}
      />
    </div>
  );
};

export default Index;
