import { useState } from "react";
import { Form, Input, message, Switch } from "antd";
import { Popover, Button, Space, Popconfirm } from "antd";
import { SearchOutlined, UserOutlined, CloseOutlined } from "@ant-design/icons";
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [adminChecked, setAdminChecked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const searchUser = (values) => {
    setIsEdit(false);
    setChecked(false);
    setAdminChecked(false);
    setInfo(null);
    props
      ?.request({
        url: `/user/checkphone/${values?.phoneno}`,
        method: "POST",
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setInfo(res?.data);
        }
      });
  };
  const handleSave = () => {
    if (info) {
      setLoading(true);
      props
        ?.request({
          url: `/user/addowncaruser/${props?.selected?.id}/${info?.id}/${
            checked ? 1 : 0
          }/${adminChecked ? 1 : 0}`,
          method: "POST",
          ismessage: true,
        })
        .then((res) => {
          setLoading(false);
          if (res.success) {
            form.resetFields();
            setInfo(null);
            setAdminChecked(false);
            setChecked(false);
            props?.reload();
          }
        });
    } else {
      message.error("Хэрэлэгчийн мэдээлэл татагдаагүй байна.");
    }
  };

  const handleRemove = (item) => {
    setLoading(true);
    props
      ?.request({
        url: `/user/removeowncaruser/${props?.selected?.id}/${item?.id}`,
        ismessage: true,
        method: "POST",
      })
      ?.then((res) => {
        setLoading(false);
        if (res.success) {
          form.resetFields();
          props?.reload();
        }
      });
  };

  const handleEdit = (item) => {
    setChecked(item.islocation === 1);
    setAdminChecked(item.issuper === 1);
    setInfo(item);
    setIsEdit(true);
  };
  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={
        <div className="">
          <div className="flex flex-col gap-1 mb-4">
            {props?.rescarusers?.map((item, i) => (
              <div
                key={i}
                onClick={() => handleEdit(item)}
                className="flex items-center justify-between border-b pb-1 cursor-pointer"
              >
                <span>
                  {item?.phone}
                  <span className="ml-1" style={{ fontSize: 10 }}>
                    {item.issuper ? "/Админ/" : "/Хэрэглэгч/"}
                  </span>
                </span>
                <Popconfirm
                  title={() => {
                    return (
                      <div className="flex flex-col">
                        <span>Хэрэглэгч хасахдаа итгэлтэй байна уу?</span>
                      </div>
                    );
                  }}
                  placement="bottom"
                  onConfirm={() => handleRemove(item)}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    loading={loading}
                    icon={<CloseOutlined />}
                    size="small"
                  />
                </Popconfirm>
              </div>
            ))}
          </div>
          <Form form={form} onFinish={searchUser}>
            {info ? (
              <div className="py-2 mb-2">
                <span className="text-sm text-success block">
                  Хэрэглэгч олдлоо: {info?.username}
                </span>
                <div className="flex items-center justify-between">
                  <span className="text-xs">GPS харуулах эсэх</span>
                  <Switch
                    size="small"
                    className="mt-2"
                    onChange={(e) => setChecked(e)}
                    checked={checked}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-xs">Admin болгох эсэх</span>
                  <Switch
                    size="small"
                    className="mt-2"
                    onChange={(e) => setAdminChecked(e)}
                    checked={adminChecked}
                  />
                </div>
              </div>
            ) : null}
            <Form.Item
              name="phoneno"
              rules={[{ required: true, message: "Утасны дугаар оруулна уу" }]}
            >
              <Space.Compact
                style={{
                  width: "100%",
                }}
              >
                <Input placeholder="Утасны дугаар" />
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  icon={<SearchOutlined />}
                />
              </Space.Compact>
            </Form.Item>
            {info ? (
              <Popconfirm
                title={() => {
                  return (
                    <div className="flex flex-col">
                      <span>{`Хэрэглэгч ${
                        isEdit ? "засахдаа" : "нэмэхдээ"
                      } итгэлтэй байна уу?`}</span>
                    </div>
                  );
                }}
                placement="bottom"
                onConfirm={handleSave}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button loading={loading} type="primary" className="w-full">
                  {isEdit ? "Засах" : "Нэмэх"}
                </Button>
              </Popconfirm>
            ) : null}
          </Form>
        </div>
      }
      title={props?.translate("main.addusercar")}
    >
      <Button
        // icon={<img src={iLocation} alt="iLocation" className="h-5 icon" />}
        icon={<UserOutlined />}
        className="flex items-center w-full font-semibold mt-2"
      >
        {props?.translate("main.addusercar")}
      </Button>
    </Popover>
  );
};

export default Index;
