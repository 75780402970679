import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import { useEffect, useRef, useState } from "react";
import { CarOutlined } from "@ant-design/icons";

const RecenterAutomatically = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    if ((lat, lng)) {
      map.setView([lat, lng]);
    }
  }, [lat, lng]);
  return null;
};

function Index(props) {
  const [location, setLocation] = useState(null);
  const { imei } = props?.selectedCar;
  const isDemo = props?._auth?.user?.phone === "demo";
  const mapRef = useRef();
  useEffect(() => {
    if (isDemo) {
      setLocation([47.929239, 106.921784]);
    } else if (
      props?.selectedCar &&
      props?.info[imei]?.lattitude &&
      props?.info[imei]?.longitude
    ) {
      setLocation([props?.info[imei]?.lattitude, props?.info[imei]?.longitude]);
    } else setLocation(null);
  }, [props?.selectedCar, props?.info]);
  const speed = props?.info[imei]?.curr_speed || 0;
  return (
    <div className={`w-full h-full relative`}>
      <MapContainer
        center={location ? location : [47.929239, 106.921784]}
        zoom={16}
        scrollWheelZoom={true}
      >
        <div className="absolute top-3 right-3 text-white bg-active px-3 py-2 rounded-full flex items-center justify-center transition transition-all">
          <CarOutlined className="text-xl" />

          <span className="text-lg ml-2">
            {speed?.toFixed(0)} <span className="text-xs">km/h</span>
          </span>
        </div>
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        {props?.selectedCar && location && <Marker position={location} />}
        <RecenterAutomatically lat={location?.[0]} lng={location?.[1]} />
      </MapContainer>
    </div>
  );
}
export default Index;
