import { useEffect, useState } from "react";
import { DatePicker, Input, InputNumber, Select, Checkbox, Radio } from "antd";
import Upload from "../Upload";
import { ScanOutlined } from "@ant-design/icons";
import { Otp } from "..";
import { isMobile } from "react-device-detect";
const { TextArea } = Input;
const Index = (props) => {
  const [otp, setOtp] = useState("");
  useEffect(() => {
    if (props?.type === "otp") props?.form?.setFieldValue(props?.name, otp);
  }, [otp]);

  const goQr = () => {
    if (props?.setQrOpen) {
      props?.setQrOpen(true);
    }
  };
  switch (props?.type) {
    case "date":
      return <DatePicker className="w-full" {...props} />;
    case "input":
      return (
        <Input
          {...props}
          className="w-full"
          addonAfter={
            props?.name === "imei" && isMobile ? (
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={goQr}
              >
                <ScanOutlined />
              </div>
            ) : null
          }
        />
      );
    case "inputpassword":
      return <Input.Password {...props} />;
    case "textarea":
      return <TextArea {...props} />;
    case "upload":
      return <Upload {...props} />;
    case "numberinput":
      return <InputNumber {...props} className="w-full" />;
    case "select":
      return <Select {...props} value={props?.value} allowClear />;
    case "checkbox":
      return <Checkbox {...props}>{props?.placeholder}</Checkbox>;
    case "otp":
      return <Otp setOtp={setOtp} otp={otp} size={props?.size} />;
    case "radio":
      return <Radio.Group {...props} />;
    default:
      return <Input {...props} />;
  }
};

export default Index;
