import { Button, Tooltip, message, Drawer, Popover, Form, Select } from "antd";
import { LoadingOutlined, HistoryOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Modal as CustomModal, Otp } from "..";
import { useRef, useState } from "react";
import iLock from "../../assets/icons/iLock.png";
import iUnlock from "../../assets/icons/iUnlock.png";
import iOff from "../../assets/icons/iOff.png";
import iOn from "../../assets/icons/iOn.png";
import { licenseModal } from "../../utils";
import { isMobile } from "react-device-detect";
import useSound from "use-sound";
import startSound from "../../assets/sounds/startSound.mp3";
import offSound from "../../assets/sounds/offSound.mp3";
import lockSound from "../../assets/sounds/lockSound.mp3";
import dayjs from "dayjs";
import { useLongPress } from "use-long-press";

const Btn = ({ icon, onClick, value, loading, text, className }) => {
  const [timer, setTimer] = useState(null);
  const [progress, setProgress] = useState(0);
  const startProgress = () => {
    setProgress(0);
    let currentProgress = 0;
    const interval = setInterval(() => {
      currentProgress += 1;
      setProgress(currentProgress);

      if (currentProgress >= 100) {
        clearInterval(interval);
      }
    }, 20);

    setTimer(interval);
  };

  const cancelProgress = () => {
    clearInterval(timer);

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevProgress - 1;
      });
    }, 1);
  };

  const bind = useLongPress(
    () => {
      if (onClick && !loading) onClick(value);
      setProgress(0);
      clearInterval(timer);
    },
    {
      threshold: 2000,
      onStart: () => {
        startProgress();
      },
      onCancel: () => {
        if (progress < 100) {
          cancelProgress();
        }
      },
    }
  );

  return (
    <button
      className="relative flex items-center justify-center w-full bg-white dark:bg-antddark rounded-xl border py-4"
      {...bind()}
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div
            className="rounded-lg bg-light_card"
            style={{
              height: "100%",
              width: `${progress}%`,
              backgroundColor: "red",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          ></div>
          <img
            src={icon}
            alt={value}
            className={`z-10 w-10 pointer-events-none ${className}`}
          />
        </>
      )}
    </button>
  );
};

const Index = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [history, setHistory] = useState([]);
  const [openHistory, setOpenHistory] = useState(false);
  const cmdRef = useRef();
  const navigate = useNavigate();
  const [start] = useSound(startSound);
  const [off] = useSound(offSound);
  const [lock] = useSound(lockSound);

  const callStartSound = () => {
    start();
    props?.request({
      url: `/user/car/powertime/start/${props?.data?.id}`,
      method: "POST",
    });
  };

  const onCloseHistory = () => {
    setOpenHistory(false);
  };

  const onOpenHistory = () => {
    setLoading(true);
    props
      ?.request({
        url: `/user/carlog/list/${props?.data?.id}`,
        method: "POST",
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        if (res?.success) {
          setHistory(res?.data || []);
          setOpenHistory(true);
        }
      });
  };

  const checkDemoCmd = (cmd) => {
    if (cmd === 1) {
      if (props?.demoStarted) {
        message.error("Таны машин асаалттай байна.");
        return false;
      }
      return true;
    } else if (cmd === 2) {
      if (!props?.demoStarted) {
        message.error("Таны машин унтраалттай байна.");
        return false;
      }
      return true;
    }
    return true;
  };

  const sendDemoCmd = (cmd) => {
    if (checkDemoCmd(cmd)) {
      if (cmd === 1) {
        props?.setLoading({ "1_Demo": true });
        setTimeout(() => {
          props?.setLoading({ "1_Demo": false });
          props?.setDemoStarted(true);
          callStartSound();
          message.success("Таны Demo машин аслаа.");
        }, 2000);
      } else if (cmd === 2) {
        props?.setLoading({ "2_Demo": true });
        setTimeout(() => {
          props?.setLoading({ "2_Demo": false });
          props?.setDemoStarted(false);
          off();
          message.success("Таны Demo машин унтарлаа.");
        }, 2000);
      } else if (cmd === 3) {
        lock();
        message.success("Таны Demo машин цоожлогдлоо.");
      } else if (cmd === 4) {
        lock();
        message.success("Таны Demo машиний цоож тайлагдлаа.");
      }
    }
  };

  const handleControl = (cmd) => {
    if (props?.isDemo) {
      sendDemoCmd(cmd);
      //message.error(props?.translate("main.democontrol"));
    } else if (props?.reslicenseinfo === true) {
      if (props?.resuserdata?.ispin) {
        cmdRef.current = cmd;
        setOpen(true);
      } else {
        props?.sendCommand(cmd, props?.data?.imei);
        if (cmd === 1) callStartSound();
        else if (cmd === 2) off();
        else if (cmd === 3 || cmd === 4) lock();
      }
    } else licenseModal(navigate, props?.translate);
  };

  const handlePin = () => {
    if (!pin || pin?.length !== 4) {
      message.error(props?.translate("main.enterpin"));
      return;
    }
    setLoading(true);
    props
      ?.request({
        url: `/user/checkpin/${pin}`,
        method: "POST",
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setOpen(false);
          setPin("");
          props?.sendCommand(cmdRef?.current, props?.data?.imei);
          if (cmdRef?.current === 1) callStartSound();
          else if (cmdRef?.current === 2) off();
          else if (cmdRef?.current === 3 || cmdRef?.current === 4) lock();
        }
      });
  };
  const onClose = () => setOpen(false);

  return (
    <div className="w-full mt-2 p-2 bg-white dark:bg-black rounded-2xl ">
      <div className="full mb-1 flex justify-between items-center">
        <span className="text-md font-semibold">
          {props?.translate("main.remote")}
        </span>
        <Button
          type="link"
          icon={<HistoryOutlined />}
          className="text-xs"
          loading={loading}
          onClick={onOpenHistory}
        >
          {props?.translate("main.loghistory")}
        </Button>
      </div>
      <div
        className={`grid grid-cols-2 sm:grid-cols-2 ${
          props?.data?.islock ? "md:grid-cols-4" : "md:grid-cols-2"
        } gap-2`}
      >
        {props?.data?.islock === 1 && (
          <Btn
            icon={iLock}
            value="lock"
            className={props?.theme === "dark" ? "active-control" : ""}
            text={props?.translate("main.lock")}
            onClick={() => handleControl(3)}
          />
        )}
        {props?.data?.islock === 1 && (
          <Btn
            icon={iUnlock}
            value="unlock"
            className={props?.theme === "dark" ? "active-control" : ""}
            text={props?.translate("main.unlock")}
            onClick={() => handleControl(4)}
          />
        )}

        <Btn
          icon={iOn}
          value="start"
          loading={props?.loading[`1_${props?.data?.imei}`]}
          text={props?.translate("main.start")}
          onClick={() => handleControl(1)}
        />

        <Btn
          icon={iOff}
          value="off"
          text={props?.translate("main.off")}
          loading={props?.loading[`2_${props?.data?.imei}`]}
          onClick={() => handleControl(2)}
        />
      </div>
      <CustomModal
        title={props?.translate("main.enterpin1")}
        open={open}
        onClose={onClose}
        isShowFooter={false}
        height={200}
      >
        <div className="p-5 flex flex-col justify-between h-full">
          <div className="flex items-center justify-center h-full">
            <Otp otp={pin} setOtp={setPin} size={"large"} open={open} />
          </div>
          <Button
            className="w-full mt-2"
            type="primary"
            onClick={handlePin}
            loading={loading}
          >
            {props?.translate("main.enter")}
          </Button>
        </div>
      </CustomModal>
      <Drawer
        title={props?.translate("main.loghistory")}
        placement="right"
        width={isMobile ? "80%" : 500}
        onClose={onCloseHistory}
        open={openHistory}
      >
        <div className="flex flex-col py-2 px-5 w-full gap-2">
          {history?.length ? (
            history?.map((item, i) => (
              <div className="bg-white dark:bg-card border border-light_border shadow-md rounded-md p-3">
                <div className="flex justify-between text-xs">
                  <div className="flex items-center">
                    <div className="bg-active flex items-center justify-center text-white mr-2 rounded-full w-6 h-6">
                      {i + 1}
                    </div>
                    <span>{item?.insbyname}</span>
                  </div>
                  <span>
                    {dayjs(item?.insymd)?.format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                </div>
                <span className="mt-2 block text-sm">{item?.result}</span>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center py-10">
              <span className="text-border block w-full text-center">
                {`${props?.data?.number} машинд үйлдлийн түүх байхгүй байна`}
              </span>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default Index;
