import { useState, useContext } from "react";
import { IsEnable, CarType, UserType } from "../enums";
import { PAYMENT_EDIT_FORM } from "../data/forms";
import dayjs from "dayjs";
import { URLS } from "./index";
import { Modal, Form } from "../components";
import {
  Button,
  Tag,
  message,
  Popconfirm,
  Form as AntForm,
  Tooltip,
} from "antd";
import { GlobalContext } from "../context";
import noimage from "../assets/icons/noimage.png";

const Index = ({ value, record, format, formData, url, method }) => {
  const context = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState({
    carOpen: false,
  });
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editForm] = AntForm.useForm();
  const onClose = (attr) => {
    setIsOpen((e) => {
      return { ...e, [attr]: false };
    });
  };
  const openModal = (attr) => {
    setIsOpen((e) => {
      return { ...e, [attr]: true };
    });
  };

  const saveCars = (values, record) => {
    setLoading(true);
    context
      ?.request({
        url: "/admin/concar",
        method: "PUT",
        ismessage: true,
        body: {
          ...values,
          userid: record?.id,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res?.success) {
          onClose("carOpen");
        }
      });
  };

  const saveEdit = (values, record) => {
    setLoading(true);
    context
      ?.request({
        url: "/license/editpay",
        method: "POST",
        ismessage: true,
        body: {
          ...values,
          day: values?.day ? Number(values.day) : 0,
        },
      })
      .then((res) => {
        setLoading(false);
        setIsOpenEdit(false);
        editForm?.resetFields();
      });
  };

  const handleEdit = (e) => {
    if (e?.isactive !== 1) setIsOpenEdit(true);
    else message.error("Амжилттай гүйлгээг засах боломжгүй");
  };

  const handleDelete = () => {
    if (url) {
      context?.request({
        url: `${url}/${record?.id}`,
        method: method || "GET",
        ismessage: true,
        model: "reload",
      });
    } else {
      let id = record?.id;
      let url = "/notification/admin";
      context?.request({
        url: `${url}/${id}`,
        method: "DELETE",
        ismessage: true,
        model: "reload",
      });
    }
  };

  const sendSeen = () => {
    context
      ?.request({
        url: `/feedback/seen/${record.id}`,
      })
      ?.then((res) => {
        if (res?.success) record.isseen = 1;
      });
  };
  switch (format) {
    case "date":
      return value ? dayjs(value).format("YYYY-MM-DD") : "-";
    case "datetime":
      return value && dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    case "actions": {
      if (value === 1) return "Асаах";
      else if (value === 2) return "Унтраах";
      else if (value === 3) return "Цоожлох";
      else if (value === 4) return "Цоож гаргах";
      else if (value === 5) return "Мэдээлэл";
      else if (value === 8) return "Тормоз гишгэх";
      else if (value === 11) return "Хүчдэл багасах";
      else if (value === 9) return "Унтраах үйлдэлүүд цуцлагдах";
      else if (value === 10) return "Түлхүүр 3 дарсан";
      return value;
    }
    case "paymentstatus": {
      if (value === 0)
        return (
          <Tag className="w-20" color="red">
            Амжилтгүй
          </Tag>
        );
      if (value === 1)
        return (
          <Tag className="w-20" color="green">
            Амжилттай
          </Tag>
        );
      if (value === 2)
        return (
          <Tag className="w-20" color="orange">
            Архив
          </Tag>
        );
    }
    case "edit":
      return (
        <div>
          <Button
            className="text-blue cursor-pointer"
            type="dashed"
            onClick={() => {
              handleEdit(record);
              /*  localStorage.setItem("editRow", JSON.stringify(row)); */
            }}
          >
            Засах
          </Button>
          <Modal
            title={context?.translate("main.edit")}
            open={isOpenEdit}
            onClose={() => setIsOpenEdit(false)}
            isShowFooter={false}
            loading={loading}
            width="20%"
          >
            <Form
              btnText="Хадгалах"
              handleSave={(e) => saveEdit(e, record)}
              form={editForm}
              initialValues={{
                userid: record?.userid,
                statementid: record?.statementid,
              }}
              loading={loading}
              data={PAYMENT_EDIT_FORM}
              {...context}
            />
          </Modal>
        </div>
      );
    case "status":
      return value === IsEnable.Идэвхтэй ? "Идэвхтэй" : "Идэвхгүй";
    case "iskey":
      return value ? (value === IsEnable.Идэвхтэй ? "Түлхүүр" : "Кноп") : "";
    case "price":
      if (value)
        return new Intl.NumberFormat().format(Math.round(value * 100) / 100);
      return 0;
    case "isactive":
      if (value === null || value === 0) {
        return <Tag color="red">Амжилтгүй</Tag>;
      } else if (value === 1) {
        return <Tag color="green">Амжилттай</Tag>;
      }
      return null;
    case "cartype":
      if (value === null) {
        return null;
      } else if (value === CarType.Sedan) {
        return <span>Sedan</span>;
      } else if (value === CarType.Cuv) {
        return <span>Cuv</span>;
      } else if (value === CarType.Suv) {
        return <span>Suv</span>;
      } else if (value === CarType.Hatchback) {
        return <span>Hatchback</span>;
      } else if (value === CarType.Van) {
        return <span>Van</span>;
      }
      return null;
    case "feedtype":
      if (value === null) {
        return null;
      } else if (value === 1) {
        return <span>Санал хүсэлт</span>;
      } else if (value === 2) {
        return <span>Урам</span>;
      } else if (value === 3) {
        return <span>Асуулт</span>;
      } else if (value === 4) {
        return <span>Шүүмж</span>;
      } else if (value === 5) {
        return <span>Програмын алдаа</span>;
      }
      return null;
    case "seen":
      if (value === null) {
        return null;
      } else if (value === 1) {
        return <Tag color="green">Харсан</Tag>;
      } else if (value === 0) {
        return <Tag color="red">Хараагүй</Tag>;
      }
      return null;
    case "operator":
      if (value === null) {
        return null;
      } else if (value === 1) {
        return <Tag color="red">Мобиком</Tag>;
      } else if (value === 2) {
        return <Tag color="green">Юнител</Tag>;
      } else if (value === 3) {
        return <Tag color="yellow">Жи-мобайл</Tag>;
      }
      return null;
    case "usertype":
      if (value === null) {
        return null;
      } else if (value === UserType.Супер_админ) {
        return <span>Супер админ</span>;
      } else if (value === UserType.Админ) {
        return <span>Админ</span>;
      } else if (value === UserType.Хэрэглэгч) {
        return <span>Хэрэглэгч</span>;
      }
      return null;
    case "see":
      return (
        <div>
          <Button
            type="dashed"
            onClick={() => {
              sendSeen();
              openModal("carOpen");
            }}
          >
            Харах
          </Button>
          <Modal
            title={context?.translate("sider.feedback")}
            open={isOpen?.carOpen}
            onClose={() => onClose("carOpen")}
            isShowFooter={false}
            loading={loading}
          >
            <span className="text-center">"{record.text}"</span>
          </Modal>
        </div>
      );
    case "img":
      if (value) {
        return (
          <div className="flex items-center justify-center">
            <Tooltip title={<img src={`${URLS.main}/${value}`} />}>
              <img
                className="table-img h-10 w-10 rounded-md"
                src={`${URLS.main}/${value}`}
                alt="img"
                onError={({ currentTarget }) => {
                  currentTarget.src = noimage;
                }}
              />
            </Tooltip>
          </div>
        );
      } else {
        return <span>Зураггүй</span>;
      }
    case "delete":
      return (
        <Popconfirm
          title={() => {
            return (
              <div className="flex flex-col">
                <span>Устахдаа итгэлтэй байна уу?</span>
              </div>
            );
          }}
          placement="topLeft"
          onConfirm={handleDelete}
          okText="Тийм"
          cancelText="Үгүй"
        >
          <span className="text-red-600 cursor-pointer">Устгах</span>
        </Popconfirm>
      );
    case "formmodal":
      return (
        <div>
          <Button type="dashed" onClick={() => openModal("carOpen")}>
            {context?.translate("main.register")}
          </Button>
          <Modal
            title={context?.translate(formData?.title)}
            open={isOpen?.carOpen}
            onClose={() => onClose("carOpen")}
            isShowFooter={false}
            loading={loading}
            width="20%"
          >
            <Form
              btnText="Хадгалах"
              handleSave={(e) => saveCars(e, record)}
              initialValues={formData?.initialValues}
              loading={loading}
              data={formData?.data}
              {...context}
            />
          </Modal>
        </div>
      );
    default:
      return value;
  }
};

export default Index;
